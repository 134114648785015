<template>
  <div class="pageContainer">
          <HsAdmin></HsAdmin>
  </div>
</template>

<script>
import axios from 'axios';

import HsAdmin from '@/components/highscore/HsAdmin.vue';


import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";


export default {
  name: 'SetUp',

  components: {
    HsAdmin,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  computed: {
     ...mapGetters([
    ]),
  },

  
  // watch: {
  //   arduinoData: function (_neu, _alt) {
  //     this.checkForRelaisAction(_neu.analogIn)
  //   }
  // },

  methods: {
  },
}
</script>

<style>
 .deviceContainer {
   width: 100vw;
   height: 100vh;
   overflow: scroll;
 }

  .c1 {
    background-color: lemonchiffon;
  }
  .c2 {
    background-color: lavender;
  }
  .c3 {
    background-color: silver;
  }
  .c4 {
    background-color: azure;
  }
  .c5 {
    background-color: lavenderblush;
  }
  .c6 {
    background-color: #A2AfAa;
  }


</style>
